import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import TakePhotoModal from "../components/take-photo-modal";
import SubmitPhotoModal from "../components/submit-photo-modal";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";
import { onboardModel } from "entities/onboard";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { ContentType } from "shared/api/http-client";
import { blobToFile } from "helpers/functions";
import { DocumentType } from "static/types";
import "./style.scss";
import VerificationStepsHeader from "../components/verification-steps-header";
import { accountStore } from "entities/account";

enum ModalStep {
  Zero,
  Photo,
  Submit,
}

export const SelfieWithDocumentPage = () => {
  const [step, setStep] = useState<ModalStep>(ModalStep.Zero);
  const [image, setImage] = useState<any>();
  const { synckyc, kyc } = useStore(accountStore);
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("onboarding-auth");
  const { t: a } = useTranslation("app");
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.proofOfAddressAddressPhotoPage);

    return () => setLink("");
  }, []);

  const onOpen = () => {
    setStep(ModalStep.Photo);
  };

  const SubmitPhoto = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.document(
      {
        idDocType: "SELFIE",
        fiz: blobToFile(image, `SELFIE.jpg`),
      },
      { type: ContentType.FormData }
    );
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err(String(resp.error.code)));
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const kyc = resp.data.data;
      synckyc(kyc);

      if (kyc.is_armenia_resident) {
        navigate(PATH_LIST.account, {
          state: { verificationRequestModal: true },
        });
      } else {
        navigate(PATH_LIST.proofOfAddressAddressPhotoPage);
      }
    }
    setIsSubmit(false);
  };

  return (
    <>
      <div className="proof-of-address">
        <VerificationStepsHeader
          percent={kyc?.is_armenia_resident ? 100 : 71.25}
          step={kyc?.is_armenia_resident ? 2 : 5}
          total={kyc?.is_armenia_resident ? 2 : 7}
        />

        <div className="proof-of-address-main">
          <p className="register-title">{t("selfieWithPassportID")}</p>
          <span className="register-subtitle">
            {t("focusCameraFacePassportID")}
          </span>

          <div className="btn-wrapper">
            <Button onClick={onOpen} type="submit" variant={"dark"}>
              {t("takeSelfie")}
            </Button>
          </div>
        </div>
      </div>

      {step === ModalStep.Photo && (
        <TakePhotoModal
          type={DocumentType.Selfie}
          onOpen={(image: any) => {
            setImage(image);
            setStep(ModalStep.Submit);
          }}
          onClose={() => {
            setStep(ModalStep.Zero);
          }}
        />
      )}

      {step === ModalStep.Submit && (
        <SubmitPhotoModal
          type={DocumentType.Selfie}
          onSubmit={SubmitPhoto}
          image={image}
          onClose={() => setStep(ModalStep.Photo)}
          isSubmit={isSubmit}
        />
      )}
    </>
  );
};
